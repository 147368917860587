<template>
  <div>
    <!--table sellPoint-->
    <ek-table
      @delete-selected="deleteContact"
      :columns="columns"
      :items="listContactUs"
      @details="contactUsDetail"
    >
      <template slot="items.contactDate" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
      <template slot="items.response" slot-scope="{ props }">
        <span v-if="props.row.response != null" class="badge badge-success rounded">
          تم الرد</span
        >
        <span v-else class="badge badge-danger rounded"> لم يتم الرد</span>
      </template>

        <template slot="items.contactUsType" slot-scope="{ props }">
        <span v-if="props.row.contactUsType != 1" class="badge badge-success rounded">
         طالب</span
        >
        <span v-else class="badge badge-danger rounded">حساب نقاط</span>
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      contacts: ({ contactUs }) => contactUs.contacts,
      columns: (state) => state.contactUs.columns,
      contactStatus: (state) => state.contactUs.contactStatus,
    }),
    ...mapGetters(["listContactUs"]),
  },
  data: () => ({
    contactListData: [],
    selectedIds: [],
    allSelected: false,
  }),
  methods: {
    ...mapActions(["getContactsList", "deleteContacts","getGetUnRead"]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    contactUsDetail({ row }) {
      this.$router.push(`/admin/contactUs/${row.id}`);
    },
    deleteContact(e) {
      this.deleteContacts(e);
    },

    //selectAll to deleteRang
    selectAll() {
      if (this.allSelected) {
        const selectedIds = this.contacts.map((u) => u.id);
        this.selectedIds = selectedIds;
      } else {
        this.selectedIds = [];
      }
    },
    deleteCon() {
      this.deleteContacts(this.selectedIds);
    },
  },
  created() {
    this.getContactsList();
    this.getGetUnRead()

  },
};
</script>
